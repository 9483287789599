// FLEX
.flex {
  display: flex;
}

.f-column {
  @extend .flex;
  flex-direction: column;
}

.f-wrap {
  @extend .flex;
  flex-wrap: wrap;
}

.f-c-c {
  @extend .flex;
  align-items: center;
  justify-content: center;
}

.f-c-sb {
  @extend .f-c-c;
  justify-content: space-between;
}

.f-c-s {
  @extend .f-c-c;
  justify-content: start;
}

.f-c-e {
  @extend .f-c-c;
  justify-content: flex-end;
}

.f-c-str {
  @extend .f-c-c;
  justify-content: stretch;
}

.f-s-c {
  @extend .f-c-c;
  align-items: start;
}

.f-s-e {
  @extend .f-c-c;
  justify-content: flex-end;
}

.f-e-s {
  @extend .flex;
  align-items: flex-end;
}

.f-e-c {
  @extend .f-e-s;
  justify-content: center;
}

.f-e-sb {
  @extend .f-e-s;
  justify-content: space-between;
}

.f-str-c {
  @extend .f-c-c;
  align-items: stretch;
}

.f-s-sb {
  @extend .flex;
  justify-content: space-between;
}

.f-b-sb {
  @extend .f-s-sb;
  align-items: baseline;
}

.f-str-s {
  @extend .flex;
  align-items: stretch;
}

.f-s-str {
  @extend .flex;
  align-content: stretch;
}

.f-sb-s {
  @extend .f-column;
  @extend .f-s-sb;
}

.w100 {
  width: 100%;
}

.w50 {
  width: 50%;
}

.h100 {
  height: 100%;
}

.invisible {
  opacity: 0;
}

.rounded {
  border-radius: $border-radius;
  overflow: hidden;
}

.space-left {
    margin-left: 1em;
}

// POSITION
// -------------------------
.relative {
  position: relative;
}
