
// TEXT
// --------------------------
.highlight {
  color: $primary-color;
}

.label {
  font-weight: 500;
  color: $label-color;
}

.hint {
  font-weight: 600;
  color: $hint-color;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.txt-center {
  text-align: center;
}


.link {
  color: $base-link-color;
  text-decoration: underline;
  outline: none;

  &:hover {
    cursor: pointer;
  }
}
