
%listreset {
  margin: 0;
  padding: 0;
  list-style: none;
}

%clearfix {
  &:after {
      content: '';
      display: block;
      clear: both;
  }
}

$animation-speed: .3s !default;
$easing-default: linear;

@mixin animate($properties, $duration: $animation-speed, $easing: $easing-default) {
	$list:();
	@each $prop in $properties {
		$str: #{$prop} #{$duration} #{$easing};
		$list: join($list, #{$str}, comma);
	}
	transition: $list;
}
