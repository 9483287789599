.card {
  position: relative;
  padding: $card-padding;
  margin: $card-margin;
  border-radius: $border-radius;
  background-color: $card-background;
  box-shadow: $card-box-shadow;
}

.close-btn {
  position: absolute;
  right: -0.5em;
  top: -0.5em;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
