::-webkit-input-placeholder {
  color: $placeholder-color !important;
}
::-moz-placeholder {
  color: $placeholder-color !important;
}
::placeholder {
  color: $placeholder-color !important;
}

::-webkit-scrollbar-button {
  background-image: none;
  background-repeat: no-repeat;
  width: 3px;
  height: 0;
}

//$scroll-empty: linear-gradient(to right, #f8f8f8 1px, #f8f8f8 1px, #d4d4d4 2px, #d4d4d4 2px, #f8f8f8 3px, #f8f8f8 3px);

::-webkit-scrollbar-track {
  background: $scroll-back-color;
  width: 1px;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background: $primary-color;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
}

::-webkit-resizer {
  background-image: none;
  background-repeat: no-repeat;
  width: 7px;
  height: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

input {
  outline: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
  -webkit-text-fill-color: #999 !important; /* цвет текста */
  color: #999 !important; /* цвет текста */

  &.material {
    -webkit-box-shadow: none !important;
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes autofill {
  to {
    background: transparent;
  }
}
