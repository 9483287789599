.thumb-wrapper {
    display: flex;
    justify-content: center;
    width: 130px;
    min-width: 130px;
    height: 130px;
    margin-right: 1em;
    overflow: hidden;
    border-radius: $border-radius;

    .thumb {
        width: 130px;
        height: 130px;
        background-size: cover;
        background-position: center;
    }
}
