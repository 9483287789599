.mat-menu-panel[role="menu"] {
    min-height: 50px;

  .mat-menu-item {
    line-height: 35px;
    height: 35px;

    .mat-icon {
      margin-right: 5px;
    }
  }
}
