// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import "./base/palette";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$theme-primary: mat.define-palette($hm-primary-palette);
$theme-accent: mat.define-palette($hm-accent-palette);

// The warn palette is optional (defaults to red).
$theme-warn: mat.define-palette($hm-warn-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme-theme: mat.define-light-theme((
        color: (
                primary: $theme-primary,
                accent: $theme-accent,
                warn: $theme-warn,
        )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }





//// Custom Theming for Angular Material
//// For more information: https://material.angular.io/guide/theming
//@import '@angular/material';
//// Plus imports for other components in your app.
//
//// Include the common styles for Angular Material. We include this here so that you only
//// have to load a single css file for Angular Material in your app.
//// Be sure that you only ever include this mixin once!
//@include mat-core();
//
//// Define the palettes for your theme using the Material Design palettes available in palette.scss
//// (imported above). For each palette, you can optionally specify a default, lighter, and darker
//// hue. Available color palettes: https://material.io/design/color/
//@import './base/palette';
//
////$theme-primary: mat-palette($hm-primary);
////$theme-primary: mat-palette($hm-primary-palette);
////$theme-accent: mat-palette($hm-accent-palette, 700);
//
//// The warn palette is optional (defaults to red).
////$theme-warn: mat-palette($hm-warn-palette);
//
//// Create the theme object (a Sass map containing all of the palettes).
//$admin-panel-theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
//
//// Include theme styles for core and each component used in your app.
//// Alternatively, you can import and @include the theme mixins for each component
//// that you are using.
//@include angular-material-theme($admin-panel-theme);
//
//// Define a custom typography config that overrides the font-family as well as the
//// `headlines` and `body-1` levels.
//$custom-typography: mat-typography-config(
//  $font-family: 'Roboto, monospace',
//  $headline: mat-typography-level(24px, 32px, 600),
//  $title: mat-typography-level(20px, 32px, 600),
//  //$body-1: mat-typography-level(16px, 24px, 500)
//);
//
//// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
////@include mat-base-typography($custom-typography);
//
//// Override typography for a specific Angular Material components.
////@include mat-checkbox-typography($custom-typography);
//
//// Override typography for all Angular Material, including mat-base-typography and all components.
////@include angular-material-typography($custom-typography);
//
//// Override the typography in the core CSS.
//@include mat-core($custom-typography);
