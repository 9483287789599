@import '~src/global';

.mat-tab-group {
  margin-top: calc($card-padding / -2);

  // Normalize Table
  .card {
    margin: 0;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    box-shadow: unset;
  }
}

.tab-content {
  padding-top: 1em;
}
