/* You can add global styles to this file, and also import other style files */
@import 'assets/styles/theme';
@import 'assets/styles/modules/variables';
@import 'assets/styles/base/common';
@import 'assets/styles/base/typography';
@import 'assets/styles/base/mixins';
@import 'assets/styles/base/hacks';
@import 'assets/styles/base/include-media';
@import 'assets/styles/components/copmonents';
@import 'assets/styles/components/forms';
@import 'assets/styles/components/snackbar';
@import 'assets/styles/components/tables';
@import 'assets/styles/components/details';
@import 'assets/styles/components/tabs';
@import 'assets/styles/components/menu';
@import 'assets/styles/components/list';
@import 'assets/styles/modules/material';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

html,
body {
    height: 100%;
    background: $base-background-color;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

a {
    color: $base-link-color;
    &:hover {
        font-weight: bold;
    }
}
